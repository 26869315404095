import React, { useContext, useRef, useState } from "react"
import { Link } from "gatsby"
import App from "../assets/image/app-store.png"
import Play from "../assets/image/play-store.png"
import Facebook from "../assets/image/facebook.svg"
import Youtube from "../assets/image/youtube.svg"
import { StateContext } from "../context/stateProvider"
import Logo from "../assets/image/logo.svg"
import newLogo from "../assets/image/newLogo.svg"
import "../data.json"
import { NavigationContext } from "../context/Navigation"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import { makePostRequest } from "../utils/request"

const pattern = {
  phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

function Footer(props) {
  const { language } = useContext(StateContext)
  const { backendHost } = require("../../config")
  const { partner } = require("../data.json")

  const subscribeInputRef = useRef(null)
  const [subscribeError, setSubscribeError] = useState(null)
  const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false)
  const subscribe = () => {
    setIsSubscribeDisabled(true)
    setTimeout(() => setIsSubscribeDisabled(false), 1000)
    const { value } = subscribeInputRef.current
    let error
    if (value) {
      if (pattern.phone.test(value)) {
        if (typeof window !== "undefined")
          window.open(
            `https://wa.me/919810109292?text=${encodeURI(
              "Hi, I would like to subscribe to Skillstrainer's updates."
            )}`
          )
      } else if (pattern.email.test(value)) {
        makePostRequest(`${backendHost}/subscribers`, { email: value })
          .then(() => {
            alert("Thank you for registering! We will contact you soon")
          })
          .catch(err => {
            console.error(err)
            alert("An error occured. Try again after refreshing the page")
          })
      } else error = "Please enter a valid phone number or email"
    } else error = "Please enter a phone number or email"
    setSubscribeError(error)
  }

  const { currentLanguage: lang } = useContext(NavigationContext)

  return (
    <MultiLangBody _key={"component.footer." + lang}>
      <div>
        <footer className="my-20 mb-10 mx-auto content px-10 lg:px-0">
          <div className="border-t border-b border-gray-500 py-8">
            <div className="flex items-start lg:justify-between flex-col lg:flex-row h-full">
              <div className="w-full lg:w-1/4 flex flex-col justify-between h-full">
                <div className="flex items-center max-w-max mx-auto lg:mx-0 my-12 lg:my-0">
                  <div>
                    <Link to="/">
                      <img src={newLogo} />
                    </Link>
                  </div>
                  {/* <p className="text-4xl font-semibold ml-4 mt-1 blue-dark2">
                    <Link to="/">SkillsTrainer</Link>
                  </p> */}
                </div>
                <p className="text text-md my-12 text-center lg:text-left">
                  Copyright © Unifiers Social Ventures Pvt Ltd 2022. All rights
                  reserved
                </p>
                <div>
                  <p className="text-md font-semibold cursor-pointer text text-center lg:text-left">
                    Follow us on
                  </p>
                  <div className="flex items-center justify-center lg:justify-start cursor-pointer mt-10">
                    <a
                      href="https://www.facebook.com/SkillsTrainer-102013924883373"
                      target="_blank"
                    >
                      <img src={Facebook} />
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UCXvGIqywnl4tLxNnu-5BshQ"
                      target="_blank"
                    >
                      <img src={Youtube} className="ml-6" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="my-14 lg:my-0 text-center lg:text-left">
              <p className="text-2xl red-dark underline cursor-pointer">For Enterprise</p>
              <p className="text-2xl red-dark underline my-14 cursor-pointer">For Trainer</p>
              <p className="text-2xl red-dark underline cursor-pointer">For Student</p>
              
            </div> */}
              <div className="text my-10 lg:my-0 lg:text-left flex flex-col justify-between items-center lg:items-start w-full lg:w-auto">
                <p className="text-md font-semibold cursor-pointer">
                  <Link to="/">
                    <MultiLangField name={"home"}>Home</MultiLangField>
                  </Link>
                </p>
                <p className="text-md font-semibold cursor-pointer my-10 lg:my-16">
                  <Link to="/courses">
                    <MultiLangField name={"courses"}>Courses</MultiLangField>
                  </Link>
                </p>
                <p className="text-md font-semibold cursor-pointer">
                  <Link to="/blogs">
                    <MultiLangField name={"blogs"}>Blogs</MultiLangField>
                  </Link>
                </p>
              </div>
              <div className="text lg:m-14 lg:my-0 text-center lg:text-left flex flex-col items-center lg:items-start justify-between w-full lg:w-auto">
                <p className="text-md font-semibold cursor-pointer">
                  {" "}
                  {/* <Link to={language == "en" ? "about" : "/about"}>About</Link> */}
                  <Link
                    to={language == "en" ? "/about" : "/" + language + "/about"}
                  >
                    {partner?.website_link ? (
                      <MultiLangField name={"about"}>
                        About SkillsTrainer
                      </MultiLangField>
                    ) : (
                      <MultiLangField name={"about"}>About</MultiLangField>
                    )}
                  </Link>
                </p>
                {partner && partner.website_link && (
                  <p className="text-md font-semibold my-10 lg:my-16 cursor-pointer">
                    <a href={partner.website_link}>
                      <MultiLangField name={"aboutPartner"}>
                        About {partner.name}
                      </MultiLangField>
                    </a>
                  </p>
                )}

                <p
                  className={`text-md font-semibold  ${
                    partner?.website_link
                      ? "mt-0 mb-10 lg:mt-0 lg:mb-16"
                      : "my-10 lg:my-16"
                  } cursor-pointer`}
                >
                  <Link
                    to={
                      language == "en"
                        ? "/contactus"
                        : "/" + language + "/contactus"
                    }
                  >
                    <MultiLangField name={"contactus"}>
                      Contact Us
                    </MultiLangField>
                  </Link>
                </p>
                <p className="text-md font-semibold cursor-pointer">
                  <Link
                    to={
                      language == "en"
                        ? "/privacy"
                        : "/" + language + "/privacy"
                    }
                  >
                    <MultiLangField name={"privacyPolicy"}>
                      Privacy Policy
                    </MultiLangField>
                  </Link>
                </p>
              </div>
              <div className="w-full lg:w-1/3 my-2 lg:my-0 text">
                <div className="mx-auto max-w-max lg:mx-0 mb-16 mt-20 lg:mt-0">
                  {/* <p className="text-2xl font-semibold text-center lg:text-left">
                  Sign up now
                </p> */}
                  <button className="text-sm bg-orange text-white font-semibold rounded-lg px-16 py-3 mt-8 mx-auto lg:mx-0">
                    <MultiLangField name={"joinForFree"}>
                      Join for Free
                    </MultiLangField>
                  </button>
                </div>
                <div>
                  <p className="text-md font-semibold">Subscribe to our blog</p>
                  <div className="w-full flex items-center justify-between bg-white border-2 shadow-md p-2 sm:p-1 rounded-lg mt-8">
                    <input
                      name="courseName"
                      type="text"
                      className="w-full ml-2 py-2 outline-none mr-4 text-sm"
                      placeholder="Your Email ID or Phone Number"
                      ref={subscribeInputRef}
                    />
                    {subscribeError && (
                      <div className="text-red-500">{subscribeError}</div>
                    )}
                    <button
                      className="bg-japanese_indigo-light text-japanese_indigo font-semibold px-8 py-3 text-sm rounded-lg"
                      onClick={subscribe}
                      disabled={isSubscribeDisabled}
                    >
                      {isSubscribeDisabled ? "Subscribing..." : "Subscribe"}
                    </button>
                  </div>
                </div>
                {/* <div>
              <p className="text-2xl font-semibold">Download app</p>
              <div className="flex items-center justify-between mt-12">
                <img src={App} className="h-20 xl:h-24" />
                <img src={Play} className="h-20 xl:h-24 ml-4" />
              </div>
              </div> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </MultiLangBody>
  )
}

export default Footer
